#TechnologiesTag .technologies-tag {
  font-size: 13px;
  border: none;
  color: #4f4f4f;
  background-color: #e0e0e0;
  margin: 5px;
}

#TechnologiesTag {
  max-width: 100%;
}
