#DocumentUpload .document-uploader-ctrl > .ant-upload.ant-upload-select-text {
  width: 100%;
}

#DocumentUpload .document-uploader-ctrl > .ant-upload-list.ant-upload-list-text {
  width: 100%;
}

#DocumentUpload .download-icon {
  color: #333333;
  text-decoration: none;
}

#DocumentUpload .icon-divider {
  border-left: 1px solid #828282;
}

#DocumentUpload .file-container {
  border: 1px solid #d9d9d9;
  background-color: #f2f2f2;
  border-radius: 5px;
}

#DocumentUpload .file-name {
  text-align: left;
}

#DocumentUpload .file-name-link {
  text-decoration: none;
  color: #434343;
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 600;
}

#DocumentUpload .saved-file {
  padding: 16px 12px;
}

#DocumentUpload .saved-file-icons {
  padding: 0px !important;
  text-align: right;
}

#DocumentUpload .saved-file-icons-container {
  margin-right: 10px;
}

#DocumentUpload .upload-area {
  border: 1px solid #d9d9d9;
  background-color: #f2f2f2;
  padding: 10px;
}

#DocumentUpload .upload-text-area {
  margin-top: 10px;
  margin-bottom: 10px;
}

#DocumentUpload .upload-button-area {
  margin-top: 10px;
  margin-bottom: 10px;
}

#DocumentUpload .upload-button {
  margin-top: 10px;
  height: 50px;
  width: 100%;
  cursor: pointer;
  font-size: 15px;
  border: 1px dashed #d9d9d9;
  background-color: #ffffff;
  color: #828282;
}

#DocumentUpload .upload-button:hover {
  border-color: #28b6e3;
  color: #28b6e3;
}
.ant-upload-list-picture-card .ant-upload-list-item-info::before {
  left: 0px;
}

#DocumentUpload .upload-text-area-title {
  font-size: 15px;
  color: #4f4f4f;
}

#DocumentUpload .upload-text-area-sub-title {
  font-size: 14px;
  color: #4f4f4f;
}
