#Interviews .save-button {
  text-align: right;
}

#Interviews .formScore {
  color: #828282;
  font-size: 20px;
  text-align: left !important;
}

#Interviews .date {
  width: 100%;
}

#Interview .ck-editor__editable {
  min-height: 200px;
}
