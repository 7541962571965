.siderMenu-container,
#SiderMenu .menu-container,
.ant-layout-sider-trigger {
  background-color: #333333;
  border-radius: 7px;
}

.ant-menu-submenu-inline ul div {
  display: none;
}

.ant-layout-sider::-webkit-scrollbar {
  display: none;
}

#SiderMenu .candidates-sub-menu .ant-menu.ant-menu-dark,
.ant-menu.ant-menu-dark .ant-menu-sub {
  background-color: #222222 !important;
}

#SiderMenu .ant-menu.ant-menu-dark .ant-menu.ant-menu-sub.ant-menu-inline {
  background-color: #222222 !important;
}

#SiderMenu .logo-button {
  padding: 0;
}

#SiderMenu .logo {
  width: 50px;
  padding: 3px 1px 0 0;
  margin: 5px;
}

#SiderMenu .logo-container {
  background: #333333;
  display: flex;
  justify-content: center;
  height: 60px;
  padding: 5px;
}

#SiderMenu .ant-menu-inline-collapsed .dashboard,
#SiderMenu .ant-menu-inline-collapsed .jobOffers,
#SiderMenu .ant-menu-inline-collapsed .companies,
#SiderMenu .ant-menu-inline-collapsed .tasks,
#SiderMenu .ant-menu-inline-collapsed .interviews,
#SiderMenu .ant-menu-inline-collapsed .jobApplications,
#SiderMenu .ant-menu-inline-collapsed .groups,
#SiderMenu .ant-menu-inline-collapsed .upSell,
#SiderMenu .ant-menu-inline-collapsed .webstartedAI,
#SiderMenu .ant-menu-inline-collapsed .talent-network,
#SiderMenu .ant-menu-inline-collapsed .prospectCompanies .ant-menu-submenu-title,
#SiderMenu .ant-menu-inline-collapsed .prospectClients .ant-menu-submenu-title,
#SiderMenu .ant-menu-inline-collapsed .configuration .ant-menu-submenu-title,
#SiderMenu .ant-menu-inline-collapsed .candidates .ant-menu-submenu-title {
  padding-left: 28px !important;
}

#SiderMenu .dashboard,
#SiderMenu .jobOffers,
#SiderMenu .companies,
#SiderMenu .tasks,
#SiderMenu .interviews,
#SiderMenu .jobApplications,
#SiderMenu .groups,
#SiderMenu .upSell,
#SiderMenu .webstartedAI,
#SiderMenu .talent-network,
#SiderMenu .prospectCompanies .ant-menu-submenu-title,
#SiderMenu .prospectClients .ant-menu-submenu-title,
#SiderMenu .configuration .ant-menu-submenu-title,
#SiderMenu .candidates .ant-menu-submenu-title {
  font-size: 16px;
  text-align: left !important;
  padding-left: 12px !important;
}

.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title {
  background-color: #28b6e3;
}

#SiderMenu .ant-menu-item-only-child {
  padding-left: 12px !important;
}

#SiderMenu .ant-menu-hidden {
  background: #222222;
}

#SiderMenu .upSell .ant-menu-item-icon {
  opacity: 0.4;
}
