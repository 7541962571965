.interviews-summary-modal .modal-interview-event {
  position: absolute;
  top: 14px;
  right: 11px;
  list-style-type: none;
  color: rgb(51, 51, 51);
}

.interviews-summary-modal {
  top: 40px;
}

.interviews-summary-modal .ant-modal-content {
  min-height: 660px;
}

.interviews-summary-modal .month-title {
  margin: 0;
  font-weight: 200;
  font-size: 18px;
  text-transform: capitalize;
}

.interviews-summary-modal .ant-modal-title {
  font-size: 22px;
}

.list-container .circle-owner {
  background-color: #28b6e387;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: block;
}

.interviews-summary-modal .int-item {
  display: flex;
  padding: 10px;
  border: 1px solid rgb(191, 191, 191);
  border-radius: 5px;
  gap: 0.5rem;
  align-items: center;
  background-color: #fff;
  margin: 0 5px;
  margin-bottom: 1rem;
}

.interviews-summary-modal .day {
  height: 40px;
  width: 40px;
  background-color: #ebebeb;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: gray;
  font-weight: 200;
}

.interviews-summary-modal .month {
  text-transform: uppercase;
  font-weight: 200;
  font-size: 12px;
  color: #616161;
}

.interviews-summary-modal .list-container {
  height: 265px;
  padding: 10px;
  background-color: #eeeeee57;
  border-radius: 10px;
  overflow-y: scroll;
  position: relative;
}

.interviews-summary-modal .no-meetings {
  font-weight: 600;
  font-size: 22px;
}

.interviews-summary-modal .ant-picker-cell .ant-picker-cell-inner {
  min-width: 28px;
  height: 33px;
  line-height: 34px;
}

.interviews-summary-modal .ant-picker-cell-selected .ant-picker-cell-inner {
  color: #1b6e88 !important;
  background: #28b6e387 !important;
}
