.icon-button {
  background-color: #28b6e3;
  min-width: 120px;
  margin: 6px 3px;
  border-radius: 4px;
  border-color: #28b6e3;
  font-weight: 400;
  align-items: center;
}

.link-button {
  align-items: center;
  font-weight: 400;
}

.ant-btn-link {
  color: #28b6e3;
  background: transparent;
  border-color: transparent;
  box-shadow: none;
}
.ant-btn-link:hover {
  color: #83ddfa !important;
}

.icon-button.ant-btn-link:not(:disabled):hover {
  color: #83ddfa;
  background: transparent;
  border-color: transparent;
  box-shadow: none;
}

.icon-button:hover {
  background-color: #83ddfa;
  border-color: #83ddfa;
}

.icon-button:focus {
  background-color: #28b6e3 !important;
  border-color: #28b6e3 !important;
}

.link-button.ant-btn-link:not(:disabled):hover {
  color: #83ddfa;
  background: transparent;
  border-color: transparent;
  box-shadow: none;
}

.ant-btn-primary:hover {
  background-color: #83ddfa;
  border-color: #83ddfa;
}

.ant-btn-primary:focus {
  background-color: #28b6e3 !important;
  border-color: #28b6e3 !important;
}

.icon-button:active {
  background-color: #6fdcff;
}

.icon-button span svg {
  font-size: 16px;
}
.secondary-button {
  border: 1px solid #28b6e3;
  color: #28b6e3;
  font-weight: 400;
}

button:disabled,
button[disabled] {
  background-color: #e0e0e0 !important;
  border-color: #e0e0e0 !important;
  color: #bdbdbd !important;
  font-weight: 400 !important;
}
