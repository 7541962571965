#AssignationsList .filter-container {
  text-align: left;
  display: flex;
  flex-direction: column;
  margin-top: 12px;
}

#AssignationsList .unassign {
  margin-left: -10px;
}

#AssignationsList .accept {
  color: #28b6e3;
  cursor: pointer;
}

#AssignationsList .reject {
  color: red;
  cursor: pointer;
}

#AssignationsList .assignations-list-header {
  height: 80px;
  margin-bottom: 1em;
}

#AssignationsList .filter-title {
  color: rgba(0, 0, 0, 0.85) !important;
  font-size: 14px !important;
  font-weight: normal !important;
}

#AssignationsList .candidate-link {
  color: rgba(0, 0, 0, 0.85) !important;
  font-size: 14px !important;
  font-weight: normal !important;
}

@media (max-width: 768px) {
  #AssignationsList .assignations-list-header {
    display: flex;
    flex-direction: column;
    height: auto;
    padding-bottom: 1em;
    flex-flow: wrap !important;
    align-items: flex-start !important;
  }

  #AssignationsList .assignations-list-header .assignations-list-header-title {
    font-size: 30px;
  }
}
