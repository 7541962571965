#TextEditor {
  border-radius: 5px;
  height: 350px;
  margin-bottom: 4em;
}

#TextEditor .ql-toolbar.ql-snow {
  background-color: #fefefe;
  border-radius: 5px 5px 0 0;
}

#TextEditor .ql-container.ql-snow,
#TextEditor .ql-container.ql-snow .ql-editor {
  background-color: #fff;
  border-radius: 0 0 5px 5px;
  min-height: 300px;
}

#TextEditor .ql-toolbar.ql-snow .ql-active .ql-stroke,
#TextEditor .ql-snow.ql-toolbar button:hover,
#TextEditor .ql-snow.ql-toolbar button:hover .ql-stroke,
#TextEditor .ql-snow.ql-toolbar .ql-picker-label:hover,
#TextEditor .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
#TextEditor .ql-snow.ql-toolbar .ql-picker-item:hover,
#TextEditor .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
#TextEditor .ql-snow.ql-toolbar .ql-picker-label.ql-active {
  color: #28b6e3;
  stroke: #28b6e3;
}

#TextEditor ::-webkit-scrollbar {
  width: 8px;
  background: #f0f0f0;
  border-radius: 4px;
}

#TextEditor ::-webkit-scrollbar-thumb {
  background: #acacac;
  border-radius: 4px;
}

#TextEditor ::-webkit-scrollbar-thumb:hover {
  background: #555;
}
