/* Profile CSS */
#UserProfile .container {
  display: flex;
  flex-direction: column;
  text-align: left;
}
.dropdown-container {
  position: relative;
}
.dropdown-container:focus {
  outline-color: transparent;
  -webkit-tap-highlight-color: transparent;
  outline: none;
  padding: 15px;
}

#UserProfile .menu {
  pointer-events: none;
}

#UserProfile .logo-signout {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 1em;
}

#UserProfile .logo {
  height: 40px;
  min-width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  margin-top: 5px;
  background-color: #575756;
  border-radius: 5px;
}

#UserProfile .signout {
  pointer-events: all;
  display: flex;
  align-items: right;
  color: #575756 !important;
  height: 32px !important;
}

#UserProfile .user-data {
  display: flex;
  align-items: center;
}

#UserProfile .avatar {
  width: 88px;
  height: 88px;
  margin: 1em;
}

#UserProfile .data {
  margin-right: 1em;
}

#UserProfile .name {
  font-size: 1.3em;
  font-weight: 700;
}

#UserProfile .email {
  font-size: 1em;
}

#UserProfile .tenant {
  font-size: 1em;
  font-weight: 500;
}

#UserProfile .ant-btn {
  height: 22px;
}

#UserProfile .switch-tenant,
#UserProfile .install-plugin-link {
  margin: 0 !important;
  padding: 0 !important;
  color: #575756 !important;
}

#UserProfile .user-credentials {
  padding-left: 0.3em;
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.08);
  width: 100%;
}

#UserProfile ul {
  padding: 0;
  list-style-type: none;
}
