#TenantDisabled.tenant-disabled-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  height: 100%;
}

#TenantDisabled .switch-tenant {
  font-size: 20px;
  font-weight: 500;
  height: 45px;
  margin-top: 1rem;
}

#TenantDisabled .ws-logo {
  margin-top: 2rem;
  width: 200px;
}

#TenantDisabled .msg-tenant {
  color: #ac1519;
  margin: 0;
}

#TenantDisabled .please-contact {
  color: #333;
}

#TenantDisabled .please-contact-link {
  text-decoration: none;
  font-weight: 700;
  color: #333;
}

@media (max-width: 767px) {
  #TenantDisabled {
    padding-top: 2rem;
  }
}
