#Activities {
  height: 100%;
}

#Activities .name-activityType {
  font-weight: bold;
  padding-left: 0;
  padding-right: 0;
}

#Activities .name-activityType-left {
  text-align: left;
  font-weight: 500;
  font-size: 13px;
  color: #595757;
  margin-top: 1rem;
  margin-bottom: 3px;
  position: relative;
}

#Activities .name-activityType-right::before {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  line-height: 3px;
  background-color: rgb(5, 158, 5);
  display: inline-block;
  content: '';
  margin-right: 4px;
}

#Activities .name-activityType-right {
  text-align: right;
  font-weight: 500;
  font-size: 13px;
  color: #595757;
  margin-top: 1rem;
  margin-bottom: 3px;
  position: relative;
}

#Activities .date {
  text-align: right;
  font-weight: bold;
}

#Activities .date-right {
  text-align: right;
}

#Activities .date-left {
  text-align: left;
}

#Activities .text {
  white-space: pre-line;
}

#Activities .text-left {
  white-space: pre-line;
  text-align: left;
}

#Activities .text-right {
  white-space: pre-line;
  text-align: right;
}

#Activities .error-message {
  margin-bottom: 22px;
}

#Activities .ant-col.container {
  width: 100%;
  margin: 0 !important;
  padding: 10px;
  min-height: 55px;
}

#Activities .ant-col.container-left {
  background-color: #ffffff9c;
  border: 1px solid #8080805c;
  border-radius: 20px 20px 20px 2px;
}

#Activities .ant-col.container-right {
  background-color: #28b6e31c;
  border: 1px solid #8080805c;
  border-radius: 20px 20px 2px 20px;
}

#Activities .add-buttons-container {
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

#Activities .add-buttons-container .secondary-button {
  margin-bottom: 5px !important;
}

#Activities .add-buttons-container .icon-button {
  margin: 0px !important;
  margin-bottom: 5px !important;
}

#Activities .activities-list-drawer {
  height: 100%;
  max-height: 58vh;
  overflow-y: auto;
  margin-top: 5px;
}

#Activities .activities-list {
  height: 95%;
  overflow-y: auto;
  margin-top: 12px;
  overflow-x: hidden;
}

#Activities .ant-spin-nested-loading {
  height: 100%;
}

#Activities .activities-body {
  height: 100%;
}

#Activities .activities-list-container {
  height: 450px;
}

#Activities .activities-list-container-drawer {
  height: 100%;
  max-height: 58vh;
}

#Activities .header-divider {
  margin: 15px 0 !important;
}

#Activities .divider {
  margin: 5px 0;
}

@media (max-width: 768px) {
  #Activities .add-buttons-contianer {
    justify-content: flex-start;
  }
}

@media (max-width: 596px) {
  #Activities .add-buttons-container .col-button {
    flex: auto;
  }

  #Activities .add-buttons-container .icon-button {
    min-width: 100% !important;
  }

  #Activities .add-buttons-container .secondary-button {
    min-width: 100% !important;
  }
}
