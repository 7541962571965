.DividerMarkersColor {
  display: inline-flex;
  width: auto !important;
  text-align: center;
  max-width: 100%;
}
.DividerMarkersColor p.container {
  margin-bottom: 0px !important;
  margin: 0px !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 2px 6px !important;
  border-radius: 8px;
  font-weight: 400;
}
