#AssignTo .assignments-body {
  height: 100%;
}

#AssignTo .assignButton {
  float: right;
  min-width: 120px;
  margin: 6px 3px 6px 3px !important;
}

#AssignTo .assignments-list-container {
  height: 100%;
  margin-top: 1rem;
  padding-top: 0.5rem;
  border-top: 1px solid rgba(92, 92, 92, 0.122);
}

#AssignTo .assignments-list-drawer {
  height: 100%;
  max-height: 65vh;
  overflow-y: auto;
  margin-top: 5px;
  padding-right: 10px;
}

#AssignTo .unassign-button {
  font-weight: 500;
  color: #28b6e3;
  cursor: pointer;
  margin-right: 0.5rem;
}

#AssignTo .ant-row.container {
  width: 100%;
  margin: 0 !important;
}

#AssignTo .title,
#AssignTo .date,
#AssignTo .text,
#AssignTo .button {
  padding: 0 !important;
}

#AssignTo .title {
  font-weight: bold;
  margin-bottom: 0.5vw;
}

#AssignTo .date {
  text-align: right;
  font-weight: bold;
  margin-bottom: 0.5vw;
}

#AssignTo .text {
  white-space: pre-line;
}

#AssignTo .button {
  text-align: right;
}

#AssignTo .divider {
  margin: 5px 0 10px;
}
