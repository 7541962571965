#NotificationsCount .dropdown-container:focus {
  outline-color: transparent;
  -webkit-tap-highlight-color: transparent;
  outline: none;
  padding: 15px;
}

#NotificationsCount .menu {
  pointer-events: none;
}

#Notifications .notifications-container {
  padding-left: 18px;
  padding-right: 18px;
  width: min(100vw, 640px);
  max-height: 80vh;
  overflow-y: scroll;
}

#Notifications .notifications-filter {
  width: 400px;
}

#NotificationsMenu .ant-dropdown-menu {
  padding: 0px;
}

.avatar-notifications {
  background-color: transparent !important;
  transition: all 0.3s ease;
}

.avatar-notifications:hover {
  background-color: #ffffff1c !important;
}

#Notifications .notifications-list {
  width: 100%;
}

#Notifications .notification-avatar {
  color: #f2f2f2;
  font-size: 40px;
}

#Notifications .notification {
  width: 100%;
}

#Notifications .ant-list-pagination {
  margin-top: 5px;
}

#Notifications .ant-list-item-action {
  text-align: right;
}

#Notifications .user {
  font-weight: bold;
  margin-right: 10px;
}

#Notifications .date {
  float: right;
  margin-right: 10px;
  font-size: 15px;
}

@media (max-width: 480px) {
  #Notifications .notifications-container {
    width: 92vw !important;
    height: 450px;
    overflow-y: scroll;
  }

  #Notifications .notifications-filter {
    width: 350px;
  }

  #Notifications .date {
    float: left;
  }

  #Notifications .ant-list-item-meta-description {
    margin-top: 2em;
  }

  #Notifications .notification-description {
    margin-left: -45px;
  }

  #Notifications .ant-list-item-action {
    display: flex;
    justify-content: space-between;
  }

  #Notifications .notification-avatar {
    font-size: 30px;
  }

  #Notifications .ant-list-item-action > li {
    padding: 0;
  }
}
