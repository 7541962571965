@import url('https://fonts.googleapis.com/css?family=Inter&display=swap');
@import '~antd/dist/reset.css';

#root {
  width: 100%;
  height: 100%;
}

#main {
  width: 100%;
  height: 100%;
}

.content {
  font-family: Roboto;
  padding: 1.5vh 1.5vw 0vh 1.5vw;
  background-color: #f2f2f2;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.content::-webkit-scrollbar {
  display: none;
}

.no-layout-content {
  font-family: Roboto;
  background-color: #f2f2f2;
  width: 100%;
  height: 100%;
  overflow-x: scroll;
}

body {
  background-color: #f2f2f2 !important;
  overflow-y: auto;
}
h1 {
  font-family: Roboto;
  text-align: left;
  font-size: 36px;
  margin-bottom: 0px;
  color: #575756;
}
.ant-layout {
  background-color: #f2f2f2 !important;
  width: 100%;
  height: 100%;
}
.site-layout {
  background: #fff;
}
.ant-collapse-content {
  padding: 1.5vh 1.5vw 0 1.5vw;
}
.ant-collapse-item {
  background-color: #f7f7f7;
  border-radius: 4px;
  margin-bottom: 2vh;
  border: 0;
  overflow: hidden;
}
.ant-collapse-header {
  background-color: #e0e0e0;
}
.ant-input {
  &:hover {
    border-color: #56ccf2;
  }
}
.ant-radio-button-wrapper {
  &:hover {
    color: #56ccf2;
  }
  text-align: center;
}
.ant-radio-button-wrapper-checked {
  &:not(.ant-radio-button-wrapper-disabled) {
    &:active {
      color: #56ccf2;
      border-color: #56ccf2;
    }
  }
}
.strong {
  font-weight: bold;
}
.actionsTable {
  text-align: left;
}

.anticon-more {
  color: black !important;
  font-size: 17px;
  font-weight: bold;
}
.dynamic-delete-button {
  cursor: pointer;
  top: 0.6vh;
  font-size: 24px;
  color: #999;
  transition: all 0.3s;
  margin-left: 2%;
}
.dynamic-add-button {
  cursor: pointer;
  top: 0.6vh;
  font-size: 24px;
  color: #999;
  transition: all 0.3s;
  margin-left: 2%;
}
.search {
  border-radius: 25px;
}
input[type='number'] {
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.ant-btn-primary {
  background-color: #28b6e3;
}

.ant-btn-default:not(:disabled):hover {
  border-color: #56ccf2;
  color: #56ccf2;
}

.ant-input-number {
  width: 100% !important;
}

.ant-drawer .ant-drawer-body::-webkit-scrollbar {
  display: none;
}

.ant-drawer-header-title {
  height: 34px;
}

.ant-drawer-title {
  color: #333333;
  text-align: start;
  font-weight: 500;
}

.ant-select.readOnly {
  pointer-events: none;
}

.ant-drawer-title .ant-col {
  font-size: 17px !important;
  color: #333333;
}

.ant-notification-notice .ant-notification-notice-icon-info.anticon {
  color: #56ccf2 !important;
}

.ant-btn-default:not(:disabled):hover {
  color: #56ccf2 !important;
  border-color: #56ccf2 !important;
}

.ant-drawer-close {
  color: #333333;
  font-size: 16px;
  text-align: right;
}

.ant-drawer-content {
  border-radius: 8px 0 0 8px;
}

.ant-btn-primary:hover {
  background-color: #3dbee8 !important;
  border-color: #3dbee8 !important;
}

.ant-btn-primary:not(:disabled):hover {
  background-color: #3dbee8 !important;
  border-color: #3dbee8 !important;
}

.ant-btn-primary:focus {
  background-color: #28b6e3 !important;
  border-color: #28b6e3 !important;
  box-shadow: none !important;
}

.ant-badge-count,
.ant-scroll-number {
  background-color: #28b6e3;
  border-color: #28b6e3;
}

.ant-dropdown-menu-item-icon {
  font-size: 14px !important;
  padding-right: 3px !important;
}
.default-table-dropdown-menu {
  min-width: 180px;
}

.ant-pagination .ant-pagination-item-active {
  border-color: #28b6e3 !important;
}

.ant-pagination .ant-pagination-item-active a {
  color: #28b6e3 !important;
}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #28b6e3 !important;
}

._loading_overlay_wrapper {
  width: 100% !important;
  height: 100% !important;
  overflow: hidden;
}
._loading_overlay_overlay {
  background-color: rgba(0, 0, 0, 0.2) !important;
  overflow: hidden;
}
.ant-layout-sider-trigger {
  background-color: rgb(51, 51, 51) !important;
}

.ant-rate .ant-rate-star-second {
  color: #c4c3c3;
}

.ant-checkbox-wrapper {
  margin-inline-start: 0 !important;
}

.ant-checkbox-group,
.ant-checkbox-group {
  display: flex;
  flex-wrap: wrap;
}

.ant-table-wrapper .ant-table-pagination.ant-pagination {
  margin: 0px;
  padding: 16px 0px;
}

.ck-toolbar_grouping {
  border-radius: 10px 10px 0 0 !important;
}

.ant-collapse-header {
  border-radius: 5px !important;
}

.anticon-github,
.anticon-linkedin {
  color: #28b6e3;
}

.ant-rate-star-zero .ant-rate-star-second {
  color: #c4c3c3 !important;
}

.anticon-close.ant-drawer-close {
  color: #333333;
  width: 14px;
}

.ant-form-item-label {
  padding: 0 0 2px !important;
}

.ant-drawer-close:hover {
  color: #333333ab !important;
  background-color: transparent !important;
}

.ant-drawer-title {
  color: #333333;
}

.ant-select-multiple .ant-select-selection-item {
  border: none !important;
  color: #4f4f4f !important;
  background-color: #e0e0e0 !important;
}

.ant-btn-primary {
  background-color: #28b6e3 !important;
}

.ant-btn-default:not(:disabled):hover {
  color: #56ccf2 !important;
  border-color: #56ccf2 !important;
}

.ant-btn-default:not(:disabled):hover,
.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  color: #28b6e3 !important;
  border-color: #28b6e3 !important;
}

.ant-btn-primary {
  background-color: #28b6e3 !important;
}

.ant-tabs-tab {
  margin: 0 16px 0 16px !important;
}

.ant-tabs-nav-wrap {
  border-radius: 5px;
}

@media (max-width: 576px) {
  .ant-picker-panels > *:first-child button.ant-picker-header-next-btn {
    visibility: visible !important;
  }

  .ant-picker-panels > *:first-child button.ant-picker-header-super-next-btn {
    visibility: visible !important;
  }

  .ant-picker-panel-container,
  .ant-picker-footer {
    width: 280px !important;
  }

  .ant-picker-footer-extra > div {
    flex-wrap: wrap !important;
  }
}

@primary-color: #28B6E3;