#StatusHistory .ant-timeline.ant-timeline-label .ant-timeline-item-label {
  width: 45%;
}

#StatusHistory .container {
  min-height: 70px;
}

#StatusHistory .ant-timeline.ant-timeline-alternate .ant-timeline-item-tail,
#StatusHistory .ant-timeline.ant-timeline-right .ant-timeline-item-tail,
#StatusHistory .ant-timeline.ant-timeline-label .ant-timeline-item-tail,
#StatusHistory .ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
#StatusHistory .ant-timeline.ant-timeline-label .ant-timeline-item-head,
#StatusHistory .ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
#StatusHistory .ant-timeline.ant-timeline-right .ant-timeline-item-head-custom,
#StatusHistory .ant-timeline.ant-timeline-label .ant-timeline-item-head-custom,
#StatusHistory .ant-timeline.ant-timeline-alternate .ant-timeline-item-left .ant-timeline-item-content,
#StatusHistory .ant-timeline.ant-timeline-right .ant-timeline-item-left .ant-timeline-item-content,
#StatusHistory .ant-timeline.ant-timeline-label .ant-timeline-item-left .ant-timeline-item-content {
  left: 48%;
}

#StatusHistory .ant-timeline.ant-timeline-alternate .ant-timeline-item-left .ant-timeline-item-content,
#StatusHistory .ant-timeline.ant-timeline-right .ant-timeline-item-left .ant-timeline-item-content,
#StatusHistory .ant-timeline.ant-timeline-label .ant-timeline-item-left .ant-timeline-item-content {
  width: 40%;
}

#StatusHistory .status-change-date {
  font-size: 15px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

#StatusHistory .days-text {
  display: flex;
  color: #838383;
  font-weight: 600;
}

#StatusHistory .days {
  padding-left: 4px;
  color: #cf9852;
}

#StatusHistory .ant-timeline-item-head-green {
  border-color: rgb(78 193 41);
  background-color: rgb(78 193 41 / 55%);
}

#StatusHistory .created-byUserName,
#StatusHistory .notes {
  font-size: 15px;
  font-weight: 600;
  color: #838383;
}

@media (max-width: 390px) {
  #StatusHistory .css-dev-only-do-not-override-1m6zrtp.ant-card .ant-card-body {
    padding: 24px 0px !important;
  }
}
