#Candidates .ant-table-thead td,
#Candidates .ant-table-thead > tr > th,
#CandidatesDataTable .ant-table-thead td,
#CandidatesDataTable .ant-table-thead > tr > th,
#JobOffers .ant-table-thead td,
#JobOffers .ant-table-thead > tr > th,
#Companies .ant-table-thead td,
#Companies .ant-table-thead > tr > th,
#Assignations .ant-table-thead td,
#Assignations .ant-table-thead > tr > th,
#AssignationsList .ant-table-thead td,
#AssignationsList .ant-table-thead > tr > th,
#Interviews .ant-table-thead td,
#Interviews .ant-table-thead > tr > th,
#ProspectCompanies .ant-table-thead td,
#ProspectCompanies .ant-table-thead > tr > th,
#Matches .ant-table-thead td,
#Matches .ant-table-thead > tr > th,
#ProspectClientsMeetings .ant-table-thead td,
#ProspectClientsMeetings .ant-table-thead > tr > th,
#ExcludedCompaniesTable .ant-table-thead td,
#ExcludedCompaniesTable .ant-table-thead > tr > th,
#ContactsList .ant-table-thead td,
#ContactsList .ant-table-thead > tr > th,
#ProspectClients .ant-table-thead td,
#ProspectClients .ant-table-thead > tr > th,
#TalentNetwork .ant-table-thead td,
#TalentNetwork .ant-table-thead > tr > th,
#CodeChallengesList .ant-table-thead td,
#CodeChallengesList .ant-table-thead > tr > th,
#JobApplications .ant-table-thead > tr > th {
  background-color: #fbfbfb;
  font-weight: 500;
  padding: 10px 5px 10px 5px;
  overflow-wrap: normal;
}

#Candidates .ant-table-thead {
  border: 1px solid gray;
}

#Candidates .ant-table-body::-webkit-scrollbar,
#CandidatesDataTable .ant-table-body::-webkit-scrollbar,
#JobOffers .ant-table-body::-webkit-scrollbar,
#Companies .ant-table-body::-webkit-scrollbar,
#Assignations .ant-table-body::-webkit-scrollbar,
#AssignationsList .ant-table-body::-webkit-scrollbar,
#ScheduledTasks .ant-table-body::-webkit-scrollbar,
#Interviews .ant-table-body::-webkit-scrollbar,
#ProspectCompanies .ant-table-body::-webkit-scrollbar,
#Matches .ant-table-body::-webkit-scrollbar,
#ProspectClientsMeetings .ant-table-body::-webkit-scrollbar,
#ExcludedCompaniesTable .ant-table-body::-webkit-scrollbar,
#ContactsList .ant-table-body::-webkit-scrollbar,
#TalentNetwork .ant-table-body::-webkit-scrollbar,
#ProspectClients .ant-table-body::-webkit-scrollbar,
#JobApplications .ant-table-body::-webkit-scrollbar {
  width: 0 !important;
}

#Candidates .ant-table-fixed-header .ant-table-scroll .ant-table-header,
#CandidatesDataTable .ant-table-fixed-header .ant-table-scroll .ant-table-header,
#JobOffers .ant-table-fixed-header .ant-table-scroll .ant-table-header,
#Companies .ant-table-fixed-header .ant-table-scroll .ant-table-header,
#Assignations .ant-table-fixed-header .ant-table-scroll .ant-table-header,
#AssignationsList .ant-table-fixed-header .ant-table-scroll .ant-table-header,
#ScheduledTasks .ant-table-fixed-header .ant-table-scroll .ant-table-header,
#Interviews .ant-table-fixed-header .ant-table-scroll .ant-table-header,
#ProspectCompanies .ant-table-fixed-header .ant-table-scroll .ant-table-header,
#Matches .ant-table-fixed-header .ant-table-scroll .ant-table-header,
#ProspectClientsMeetings .ant-table-fixed-header .ant-table-scroll .ant-table-header,
#ExcludedCompaniesTable .ant-table-fixed-header .ant-table-scroll .ant-table-header,
#ContactsList .ant-table-fixed-header .ant-table-scroll .ant-table-header,
#TalentNetwork .ant-table-fixed-header .ant-table-scroll .ant-table-header,
#ProspectClients .ant-table-fixed-header .ant-table-scroll .ant-table-header,
#JobApplications .ant-table-fixed-header .ant-table-scroll .ant-table-header {
  min-width: 0px !important;
  overflow: hidden !important;
}

#Candidates .ant-table-tbody > tr > td,
#CandidatesDataTable .ant-table-tbody > tr > td,
#JobOffers .ant-table-tbody > tr > td,
#Companies .ant-table-tbody > tr > td,
#Assignations .ant-table-tbody > tr > td,
#AssignationsList .ant-table-tbody > tr > td,
#Interviews .ant-table-tbody > tr > td,
#ProspectCompanies .ant-table-tbody > tr > td,
#Matches .ant-table-tbody > tr > td,
#ProspectClientsMeetings .ant-table-tbody > tr > td,
#ExcludedCompaniesTable .ant-table-tbody > tr > td,
#ContactsList .ant-table-tbody > tr > td,
#TalentNetwork .ant-table-tbody > tr > td,
#ProspectClients .ant-table-tbody > tr > td,
#JobApplications .ant-table-tbody > tr > td {
  border-bottom: solid 1px #f0f0f0 !important;
  padding: 5px;
}

#Candidates .ant-typography,
#Candidates .ant-typography-ellipsis p,
#CandidatesDataTable .ant-typography,
#CandidatesDataTable .ant-typography-ellipsis p,
#JobOffers .ant-typography,
#JobOffers .ant-typography-ellipsis p,
#Companies .ant-typography,
#Companies .ant-typography-ellipsis p,
#Assignations .ant-typography,
#Assignations .ant-typography-ellipsis p,
#Interviews .ant-typography,
#Interviews .ant-typography-ellipsis p,
#ProspectCompanies .ant-typography,
#ProspectCompanies .ant-typography-ellipsis p,
#Matches .ant-typography,
#Matches .ant-typography-ellipsis p,
#ProspectClientsMeetings .ant-typography,
#ProspectClientsMeetings .ant-typography-ellipsis p,
#ExcludedCompaniesTable .ant-typography,
#ExcludedCompaniesTable .ant-typography-ellipsis p,
#ContactsList .ant-typography,
#ContactsList .ant-typography-ellipsis p,
#AssignationsList .ant-typography,
#AssignationsList .ant-typography-ellipsis p,
#ProspectClients .ant-typography,
#ProspectClients .ant-typography-ellipsis p,
#TalentNetwork .ant-typography,
#TalentNetwork .ant-typography-ellipsis p,
#CodeChallengesList .ant-typography,
#CodeChallengesList .ant-typography-ellipsis p,
#JobApplications .ant-typography-ellipsis p {
  margin: 0 !important;
}

#Candidates .ant-table-cell.actions,
#CandidatesDataTable .ant-table-cell.actions,
#JobOffers .ant-table-cell.actions,
#Assignations .ant-table-cell.actions,
#Companies .ant-table-cell.actions,
#Interviews .ant-table-cell.actions,
#Matches .ant-table-cell.actions,
#ProspectClientsMeetings .ant-table-cell.actions,
#ExcludedCompaniesTable .ant-table-cell.actions,
#ContactsList .ant-table-cell.actions,
#TalentNetwork .ant-table-cell.actions,
#AssignationsList .ant-table-cell.actions,
#ProspectCompanies .ant-table-cell.actions,
#ProspectClients .ant-table-cell.actions,
#JobApplications .ant-table-cell.actions {
  text-align: right;
}
