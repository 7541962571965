#IconPicker .unselected {
  text-align: center !important;
}

#IconPicker .selected {
  text-align: center !important;
  background-color: RGB(2, 177, 10, 0.5) !important;
}

#IconPicker .ant-list-bordered .ant-list-item {
  padding: 5px;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 0;
  cursor: pointer;
}

#IconPicker .ant-list-grid .ant-col > .ant-list-item {
  display: inline-flex !important;
}

#IconPicker .ant-col {
  text-align: center !important;
}
