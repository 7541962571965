#WSHRAvatar .avatar {
  margin-top: 10px;
  background: #2e2e2d !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

#WSHRAvatar .avatar-flag {
  position: absolute;
  left: 28px !important;
  max-width: 25px;
  top: 65%;
  z-index: 2;
}

.avatar-container {
  width: 100%;
  height: 100%;
  margin-left: 2px;
}
