#AppDrawer ._loading_overlay_wrapper {
  width: 100% !important;
  height: inherit !important;
  position: unset !important;
}

#AppDrawer .drawer-body {
  width: 100%;
  height: 100%;
  padding: 16px 24px;
  overflow-y: auto;
}

#AppDrawer {
  height: 100%;
}

.ant-drawer-body {
  padding: 0 !important;
}

.ant-drawer-header {
  background-color: #fafafa;
  padding: 12px 12px 8px 22px !important;
}

#AppDrawer .drawer-body {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
#AppDrawer .drawer-body::-webkit-scrollbar {
  display: none;
}
