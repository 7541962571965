#VersionHistory .date {
  font-size: 15px;
  font-weight: 500;
}

#VersionHistory .user-name {
  font-size: 15px;
  font-weight: 500;
  color: #838383;
}

#VersionHistory .user-container {
  text-align: left;
}

#VersionHistory .date-container {
  text-align: right;
}

#VersionHistory .index {
  font-size: 18px;
  font-weight: 700;
  padding-right: 6px;
  text-decoration: none;
  color: black;
}

#VersionHistory .container {
  height: 70px;
}

#VersionHistory .ant-timeline.ant-timeline-alternate .ant-timeline-item-tail,
#VersionHistory .ant-timeline.ant-timeline-right .ant-timeline-item-tail,
#VersionHistory .ant-timeline.ant-timeline-label .ant-timeline-item-tail,
#VersionHistory .ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
#VersionHistory .ant-timeline.ant-timeline-label .ant-timeline-item-head,
#VersionHistory .ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
#VersionHistory .ant-timeline.ant-timeline-right .ant-timeline-item-head-custom,
#VersionHistory .ant-timeline.ant-timeline-label .ant-timeline-item-head-custom,
#VersionHistory .ant-timeline.ant-timeline-alternate .ant-timeline-item-left .ant-timeline-item-content,
#VersionHistory .ant-timeline.ant-timeline-right .ant-timeline-item-left .ant-timeline-item-content,
#VersionHistory .ant-timeline.ant-timeline-label .ant-timeline-item-left .ant-timeline-item-content {
  left: 53%;
}

#VersionHistory .ant-timeline.ant-timeline-alternate .ant-timeline-item-left .ant-timeline-item-content,
#VersionHistory .ant-timeline.ant-timeline-right .ant-timeline-item-left .ant-timeline-item-content,
#VersionHistory .ant-timeline.ant-timeline-label .ant-timeline-item-left .ant-timeline-item-content {
  width: 25%;
  text-align: center;
}
