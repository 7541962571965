#UserNotRegistered {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 30px;
}

#UserNotRegistered #InfoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
}

#UserNotRegistered .title {
  text-align: center;
  color: #ac1519;
  padding: 0 2rem;
}

#UserNotRegistered .message {
  text-align: center;
  padding: 0 2rem;
  max-width: 900px;
  color: gray;
}

@media (max-width: 767px) {
  #UserNotRegistered {
    padding-top: 2rem;
  }
}
