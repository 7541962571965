#ImagesUpload .image-uploader {
  cursor: pointer !important;
  width: 100% !important;
  text-align: center !important;
  display: block;
  align-items: center;
  justify-content: center;
}

#ImagesUpload .upload-list-inline .ant-upload-list-item {
  width: 100%;
}

#ImagesUpload .upload-list-inline [class*='-upload-list-rtl'] .ant-upload-list-item {
  float: right;
}

#ImagesUpload .upload-list-inline .ant-upload-animate-enter {
  animation-name: uploadAnimateInlineIn;
}

#ImagesUpload .upload-list-inline .ant-upload-animate-leave {
  animation-name: uploadAnimateInlineOut;
}

.modal-preview-file .ant-modal-content {
  padding-top: 50px;
}

#ImagesUpload .image-uploader .ant-upload-select {
  min-width: 300px;
}

#ImagesUpload .upload-button {
  color: #828282;
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
  height: 100%;
}

#ImagesUpload .upload-button:hover {
  border-color: #28b6e3;
  color: #28b6e3;
}

.ant-upload-select-picture-card {
  margin: 0px !important;
}
