#CustomTag.custom-tag-container {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: left;
  flex-wrap: wrap;
}

#CustomTag .content-tag {
  font-size: 13px;
  margin: 4px;
  padding: 2px 8px;
  cursor: default;
  border: none;
  color: #4f4f4f;
  background-color: #e0e0e0;
}

#CustomTag .plus-tag {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  width: 25px;
  border-radius: 100%;
  font-size: 13px;
  border: none;
  color: #4f4f4f;
  background-color: #e0e0e0;
}
