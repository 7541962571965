@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:opsz@6..12&family=Roboto&display=swap');

#LayoutHome {
  width: 100%;
  position: relative;
}

#LayoutHome .title {
  font-size: 50px;
  font-weight: 700;
  color: #fff;
  text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  margin-bottom: 0;
}

#LayoutHome .subtitle {
  font-size: 25px;
  font-weight: 300;
  color: #fff;
  text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  margin-top: 2rem;
  padding: 0 15%;
}

@media (max-width: 768px) {
  #LayoutHome .title {
    font-size: 40px;
  }

  #LayoutHome .subtitle {
    font-size: 18px;
    padding: 0 10px;
  }
}
