#FooterHome {
  color: #fff;
  font-weight: 300;
  background: #242422 url(../../../../../img/footer-bg.png) no-repeat 0 1em;
  background-size: contain;
  min-height: 300px;
  height: calc(50vh - 660px);
  padding: 0 4.4em;
}

#FooterHome .ws-logo {
  width: 80px;
}

#FooterHome .social-link-container {
  display: flex;
  flex-direction: column;
  padding-left: 60px;
  margin-left: 5px;
  margin-top: 50px;
}

#FooterHome .social-link {
  display: flex;
  flex-direction: row;
  color: #acacac;
  font-family: Nunito Sans;
  font-size: 18px;
  font-weight: 400;
  margin-top: 5px;
}

#FooterHome .home-redirection-links-container {
  display: flex;
  flex-direction: column;
}

#FooterHome .link-pricing {
  text-decoration: none;
  color: #acacac;
  margin-bottom: 5px;
  transition: all 0.3s ease-out;
}

#FooterHome .link-pricing:hover {
  color: #c2c2c2;
}

#FooterHome .text-link {
  color: #acacac;
  font-family: Nunito Sans;
  text-align: left;
  font-size: 20px;
  font-weight: 400;
}

#FooterHome .copyright {
  font-size: 12px;
  font-family: Nunito sans;
}

#FooterHome .text-link:hover {
  color: #c2c2c2;
}
#FooterHome .badge-line {
  color: #acacac;
  font-size: 30px;
  font-weight: 400;
}

@media (max-width: 992px) {
  #FooterHome .text-link {
    font-size: 16px;
  }

  #FooterHome .social-link-container {
    padding-left: 0;
  }

  #FooterHome .social-link {
    font-size: 12px;
  }
}

@media (max-width: 768px) {
  #FooterHome img {
    width: 60 !important;
  }
}

@media (max-width: 576px) {
  #FooterHome .social-link-container {
    order: 3;
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
  }
}

@media (max-width: 480px) {
  #FooterHome {
    padding: 0 2rem;
  }
  #FooterHome .ws-logo {
    width: 60px;
  }

  #FooterHome .badge-line {
    font-size: 25px;
  }
  #FooterHome .text-link {
    font-size: 14px;
  }
  #FooterHome .social-link {
    font-size: 12px;
  }
}
