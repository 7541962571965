#NavBar .profile {
  text-align: center;
}

#NavBar .notifications {
  text-align: center;
  padding-right: 20px !important;
}

#NavBar .navBarRow {
  background: rgba(255, 255, 255, 0);
}

.avatar-calendar {
  background-color: transparent !important;
  transition: all 0.3s ease;
  cursor: pointer;
}

.avatar-calendar:hover {
  background-color: #ffffff1c !important;
}

#NavBar .search-container {
  align-items: right;
  text-align: right;
}

#NavBar .searchBar {
  width: 45%;
  border-radius: 18px;
  min-width: 500px;
}

#NavBar .ant-input-affix-wrapper-disabled {
  background-color: #fff;
}

#NavBar .logo {
  width: 50px;
  padding: 3px 1px 0 0;
  margin: 5px;
}

#NavBar .logo-container {
  background: #333333;
  display: flex;
  justify-content: center;
  height: 60px;
  padding: 5px;
}

.ant-btn-icon-only.ant-input-search-button {
  margin: 0 !important;
}

.ant-layout-header {
  padding: 0px;
}

@media (max-width: 996px) {
  #NavBar .searchBar {
    min-width: 300px;
  }
}

@media (max-width: 600px) {
  #NavBar .navBarRow {
    display: flex;
    justify-content: space-between;
    align-items: center !important;
    flex-direction: row;
  }

  .avatar-calendar,
  .avatar-notifications {
    width: 30px;
  }

  .user-avatar {
    width: 35px;
    height: 35px;
  }

  #NavBar .search-container {
    max-width: 35% !important;
    margin-left: 10px;
  }
  #NavBar .searchBar {
    margin-top: 0;
    min-width: 100%;
  }
}

@media (max-width: 420px) {
  #NavBar .search-container {
    max-width: 32% !important;
  }
}
