#GoBack-button .goBack-button {
  font-size: 16px;
  height: 30px;
  display: flex;
  color: #838383;
  cursor: pointer;
  width: fit-content;
  padding: 10px 0;
}

#GoBack-button .goBack-button:hover {
  color: #c0c0c0;
  transition: 0.2s;
}
