#Add .buttons-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#Add .back-button {
  color: #28b6e3;
  font-weight: 500;
  cursor: pointer;
}

#Add .steps-container {
  margin-bottom: 20px;
}

#Add .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: #28b6e3;
  border-color: #28b6e3;
}

#Add .ant-steps-navigation .ant-steps-item::before {
  background-color: #28b6e3;
}

#Add .ant-pagination-item-active a {
  color: #28b6e3;
}

#Add .ant-table-thead > tr > th {
  background-color: #eeeeee;
  padding: 10px 5px 10px 5px;
  overflow-wrap: normal;
}

#Add .ant-pagination-item-active {
  border-color: #28b6e3;
}
