#MenuBar {
  background-color: rgba(255, 255, 255, 0.9);
  height: 80px;
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  font-weight: 500;
  z-index: 99;
  max-width: 100vw;
}

#MenuBar .logoNav {
  width: 200px;
  vertical-align: middle;
  margin-left: 2em;
}

#MenuBar .menuBarContainer {
  align-items: center;
  height: 100%;
}

#MenuBar .ant-menu-light {
  background-color: transparent;
  border: none;
  justify-content: end;
  margin-right: 4rem;
  font-size: 17px;
}

#MenuBar .mainMenu {
  display: block;
}

.ant-menu-light.ant-menu-root.ant-menu-vertical {
  border: none;
}

#MenuBar #navBar-menu-button {
  display: none;
}

@media (max-width: 908px) {
  #MenuBar .logoNav {
    width: 170px;
    margin-left: 1em;
  }

  #MenuBar #navBar-menu-button {
    display: block;
  }

  #MenuBar .mainMenu {
    display: none;
  }

  #MenuBar .barsMenu {
    margin-right: 10px;
  }

  .mobileMenuDrawer .ant-drawer-wrapper-body .ant-menu .ant-menu-item {
    font-size: 17px;
    font-weight: 500;
    color: #404040;
  }

  .mobileMenuDrawer .ant-drawer-wrapper-body .ant-drawer-body {
    padding: 30px 24px;
  }

  .closeDrawerIcon {
    position: absolute;
    right: 15px;
    top: 14px;
    cursor: pointer;
  }
}

@media (max-width: 480px) {
  #MenuBar .logoNav {
    width: 150px;
    margin-left: 1em;
  }
}
