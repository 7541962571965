.secondaryButton {
  background-color: transparent;
  border: 2px solid #28b6e3 !important;
  padding: 4px 15px;
  color: #28b6e3 !important;
  min-width: 120px;
  margin-right: 10px;
}

.secondaryButton:disabled {
  border: none !important;
}

.secondaryButton:disabled:hover {
  background-color: #e0e0e0 !important;
}

.techName {
  margin: 0;
  font-weight: 700;
  padding-left: 5px;
  font-size: 15px;
}

.secondaryButton:hover {
  background-color: #51d0f01d !important;
}

#TechnologiesFormInputs .topTechs {
  display: flex;
  align-items: center;
  height: 100px;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

#TechnologiesFormInputs p.add-skill-button {
  cursor: pointer;
  font-weight: 500;
  color: #28b6e3;
  font-size: 16px;
  margin-right: 2rem;
}

#TechnologiesFormInputs .delete-button {
  cursor: pointer;
  font-size: 20px;
  color: #999;
}

#TechnologiesFormInputs .tech-logo {
  cursor: pointer;
  padding: 0 10px;
}

#TechnologiesFormInputs .technologies-tag {
  cursor: pointer;
  padding: 0 10px;
}

.validationColumn {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.validateButton {
  min-width: 120px;
  border: 2px solid gray !important;
  background-color: white !important;
  color: gray !important;
  cursor: auto !important;
}

.validatedButton {
  min-width: 120px;
  cursor: auto !important;

  color: white !important;
}

.validateButton:hover {
  background-color: white !important;
  border-color: gray !important;
}

@media (max-width: 768px) {
  #TechnologiesFormInputs .topTechs {
    height: 150px;
  }
}

@media (max-width: 600px) {
  #TechnologiesFormInputs .topTechs {
    height: 170px;
  }
}

@media (max-width: 480px) {
  #TechnologiesFormInputs .topTechs {
    height: 300px;
  }
}

@media (max-width: 575px) {
  .validationColumn {
    display: flex;
    align-items: flex-start;
    margin-top: 0px;
  }
}
