#LanguageFormInputs p.add-language-button {
  cursor: pointer;
  font-weight: 500;
  color: #28b6e3;
  font-size: 16px;
  margin-right: 2rem;
}

#LanguageFormInputs .delete-button {
  cursor: pointer;
  font-size: 20px;
  color: #999;
}
