#AvatarUpload .image-uploader {
  width: 100% !important;
  text-align: center !important;
}

#AvatarUpload .image-uploader-ctrl {
  cursor: pointer !important;
  background: #fff;
  border-radius: 50%;
  color: #575756;
  font-weight: 300;
  height: 200px;
  width: 200px;
  font-size: 18px;
  border: 1px solid #34353a21;
}

#AvatarUpload .uploader-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

#AvatarUpload .image-uploader-ctrl > .ant-upload.ant-upload-select-text {
  width: 100% !important;
  text-align: center !important;
}

#AvatarUpload .image-uploader-ctrl > .ant-upload-list.ant-upload-list-text {
  width: 100% !important;
  text-align: center !important;
}
